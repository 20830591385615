import React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../components/layout";
import { Container, Typography, Stack, Paper, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Study = ({ data }: PageProps<Queries.TrialPageQuery>) => {
  return (
    <Layout>
      <Container maxWidth="lg">
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            align="center"
            color="text.primary"
            gutterBottom
          >
            {data.strapiTrial?.title}
          </Typography>
          <Typography
            variant="h4"
            align="center"
            color="text.secondary"
            paragraph
          >
            {data.strapiTrial?.summary}
          </Typography>
          {
            //        <Stack direction="row" spacing={2} justifyContent="center">
            //          <Item>Gender</Item>
            //          <Item>Age</Item>
            //          <Item>Phase</Item>
            //          <Item>Sites</Item>
            //          <Item>Status</Item>
            //        </Stack>
          }
          {data.strapiTrial?.criteria
            ?.replace(`\n\n`, `\n`)
            .split(`\n`)
            .map((outer: string) => {
              if (outer.match("^(?:In|Ex)clusion Criteria:$")) {
                return (
                  <Typography
                    variant="h5"
                    align="left"
                    color="text.primary"
                    paragraph
                  >
                    {outer}
                  </Typography>
                );
              }

              return (
                <Typography
                  variant="body1"
                  align="left"
                  color="text.secondary"
                  paragraph
                >
                  {outer}
                </Typography>
              );
            })}
        </Box>
      </Container>
    </Layout>
  );
};

export default Study;

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.

export const query = graphql`
  query TrialPage($id: String, $language: String!) {
    strapiTrial(id: { eq: $id }) {
      id
      studyId
      summary
      title
      publishedAt
      updatedAt
      condition
      criteria
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export { Head } from "../head";
