import React from "react";
import { HeadFC } from "gatsby";

export const Head: HeadFC = () => (
  <>
    <title>Global Trial Finder</title>
  </>
);

//TODO graphql query fetching site details to fill in above (i.e. SEO)
